<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
		:isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('IgnoredCustomers')"
    @cancel="onCancel('IgnoredCustomers')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'CustomerCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
			isLoadingPage: false,
      controllerName: 'IgnoredCustomers',
      pageHeader: 'Создание клиента',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты, по которым не контролировать долги',
          route: { name: 'IgnoredCustomers' },
        },
        {
          text: 'Создание клиента',
        },
      ],
      initialForm: {
        customerId: null,
      },
      form: null,
    };
  },

  computed: {
		...mapState({
      customers: (state) => state.common.customers.data,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'customerId',
              label: 'Имя клиента',
							list: this.customers
            },
          ],
        },
      ];
    },
  },

	created() {
		this.isLoadingPage = true;

    const customers = this.getCommonList({ name: 'Customers' });

    customers.finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
